import { Orders, Stat } from "@liqnft/candy-shop";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { candyShop } from "../utils/candy-shop";
import styled from "styled-components";

const MarketplaceWithFilter: React.FC = () => {
  const wallet = useAnchorWallet();

  return (
    <DesContainer>
      <Stat
        candyShop={candyShop}
        title={"The Metaverse Party Beta"}
        description={
          "Filter by approved collections and search for purchasable NFTs."
        }
        style={{ paddingBottom: 50 }}
      />
      <Orders
        wallet={wallet}
        candyShop={candyShop}
        walletConnectComponent={<WalletMultiButton />}
        filters={FILTERS}
        filterSearch
        search
      />
    </DesContainer>
  );
};

export default MarketplaceWithFilter;

const FILTERS = [
  { name: "Gigachad Goat Grails", collectionId: "1", identifier: 640353209 },
  { name: "Jikan", collectionId: "2", identifier: -432050407 },
  { name: "Froots", collectionId: "3", identifier: 1850758652 },
  { name: "SOLGods", collectionId: "4", identifier: -32893523 },
  { name: "The Bridged", collectionId: "5", identifier:	-1559726534 },
  { name: "J'Am", collectionId: "6", identifier:	-1753753341 },



];

const DesContainer = styled.div`
  width: 100%;


  .candy-filter {
    color: #fff;
    background-color: #4e44ce;
    display: inline;
    border-radius: 14px;
    padding: 6px 18px;

    li:hover {
      color: #7522f5;
    }

    .candy-search input {
      padding: 10px 4px 10px 24px;
      width: 100%;
    }
  }

  .candy-stat-component-title{
    color:white;
    background-color: #4e44ce;
    border-radius: 14px;
    padding: 6px 16px;
  }

  .candy-stat-component-description{
    color:white;
    background-color: #4e44ce;
    border-radius: 14px;
    padding: 6px 16px;
  }

  .candy-label{
    color:white;

    background-color: #4e44ce;
    border-radius: 14px;
    padding: 6px 16px;
  }

  .candy-value-lg{
    color:#4e44ce;
    display:inline;
    background-color: white;
    border-radius: 14px;
    padding: 6px 16px;
  }


`;
