import { useRef } from "react";
import { CandyShop } from "@liqnft/candy-shop-sdk";
import { Orders, Stat } from "@liqnft/candy-shop";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js"
import {
  CANDY_SHOP_CREATOR_ADDRESS,
  CANDY_SHOP_TREASURY_MINT,
  CANDY_SHOP_PROGRAM_ID,
  NETWORK,
} from "../utils/candy-shop";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import styled from "styled-components";

const TroopTokenMarketplace: React.FC = () => {
  const wallet = useAnchorWallet();

  const candyShopRef = useRef<CandyShop>(
    new CandyShop({
      candyShopCreatorAddress: new PublicKey("4k1kq9JTqGUCXENuihKQ3CtX7D5PqKodJNevHBWxegZj"),
      treasuryMint: new PublicKey("Bic8w5K9tvHDY7XfiT7fLTdiAkQWURAKkcEs8EsXpxbY"),
      candyShopProgramId: new PublicKey("csbMUULiQfGjT8ezT16EoEBaiarS6VWRevTw1JMydrS"),
      env: "mainnet-beta",
      // pass additional settings param to configure shop display
      settings: {
        currencySymbol: "TROOP",
        currencyDecimals: 6,
        priceDecimals: 3,
        volumeDecimals: 1,
      },
    })
  );

  return (
    <DesContainer>
      <Stat
        candyShop={candyShopRef.current}
        title={"$TROOP Marketplace"}
        description={
          "$TROOP NFT Marketplace on Solana by @AugustF76."
        }
        style={{ paddingBottom: 50 }}
      />
      <Orders
        wallet={wallet}
        candyShop={candyShopRef.current}
        walletConnectComponent={<WalletMultiButton />}
        filters={FILTERS}
        filterSearch
        search
      />
    </DesContainer>
  );
};

export default TroopTokenMarketplace;

const FILTERS = [
  { name: "Gigachad Goat Grails", collectionId: "1", identifier: 640353209 },
];

const DesContainer = styled.div`
.candy-filter {
  color: #fff;
  background-color: #4e44ce;
  display: inline;
  border-radius: 14px;
  padding: 6px 18px;

  li:hover {
    color: #7522f5;
  }

  .candy-search input {
    padding: 10px 4px 10px 24px;
    width: 100%;
  }
}

.candy-stat-component-title{
  color:white;
  background-color: #4e44ce;
  border-radius: 14px;
  padding: 6px 16px;
}

.candy-stat-component-description{
  color:white;
  background-color: #4e44ce;
  border-radius: 14px;
  padding: 6px 16px;
}

.candy-label{
  color:white;

  background-color: #4e44ce;
  border-radius: 14px;
  padding: 6px 16px;
}

.candy-value-lg{
  color:#4e44ce;
  display:inline;
  background-color: white;
  border-radius: 14px;
  padding: 6px 16px;
}

`;
