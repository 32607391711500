import { Orders, Stat } from "@liqnft/candy-shop";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { candyShop } from "../utils/candy-shop";
import styled from "styled-components";

const Marketplace: React.FC = () => {
  const wallet = useAnchorWallet();

  return (
    <DesContainer>
      <Stat
        candyShop={candyShop}
        title={"The Metaverse Party Beta"}
        description={
          "Welcome to the Metaverse Party beta, a boutique Solana NFT exchange by @AugustF76."
        }
        style={{ paddingBottom: 50 }}
      />
      <Orders
        wallet={wallet}
        candyShop={candyShop}
        walletConnectComponent={<WalletMultiButton />}
        search
      />
    </DesContainer>
  );
};

export default Marketplace;
const DesContainer = styled.div`
.candy-filter {
  color: #fff;
  background-color: #4e44ce;
  display: inline;
  border-radius: 14px;
  padding: 6px 18px;

  li:hover {
    color: #7522f5;
  }

  .candy-search input {
    padding: 10px 4px 10px 24px;
    width: 100%;
  }
}

.candy-stat-component-title{
  color:white;
  background-color: #4e44ce;
  border-radius: 14px;
  padding: 6px 16px;
}

.candy-stat-component-description{
  color:white;
  background-color: #4e44ce;
  border-radius: 14px;
  padding: 6px 16px;
}

.candy-label{
  color:white;

  background-color: #4e44ce;
  border-radius: 14px;
  padding: 6px 16px;
}

.candy-value-lg{
  color:#4e44ce;
  display:inline;
  background-color: white;
  border-radius: 14px;
  padding: 6px 16px;
}

`;
