import { Activity } from "@liqnft/candy-shop";
import { candyShop } from "../utils/candy-shop";
import styled from "styled-components";

const ActivityView: React.FC = () => {
  return (
    <DesContainer>
      <h1 style={{ color: "white", display:"inline",marginTop: 40, marginBottom: 40, backgroundColor: "#4e44ce", borderRadius: 14, padding: 16 }}>Marketplace Activity</h1>
      <Activity candyShop={candyShop} />
    </DesContainer>
  );
};

export default ActivityView;

const DesContainer = styled.div`
  width: 100%;


  .candy-activity {
    font-size: 14px;
    .candy-activity-header{
      background-color: #C0BBFB;
      border-radius: 14px;
      padding: 6px 16px;
    }
    candy-activity-item{
      color: white;
      background-color: #4e44ce;
      border-radius: 14px;
      padding: 6px 16px;
    }
  }

  a {
    color: white;
  }
`;
