import { useRef } from "react";
import { Sell } from "@liqnft/candy-shop";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { CandyShop } from "@liqnft/candy-shop-sdk";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { candyShop } from "../utils/candy-shop";
import styled from "styled-components";
import { PublicKey } from "@solana/web3.js";
import { useCurrency } from "../components/Currency";
import { useMemo } from "react";

const SellForTroop: React.FC = () => {
  const wallet = useAnchorWallet();
  const { getCurrencySettings } = useCurrency();
  const settings = getCurrencySettings();

  const candyShop = useMemo(
    () =>
      new CandyShop({
        candyShopCreatorAddress: new PublicKey("4k1kq9JTqGUCXENuihKQ3CtX7D5PqKodJNevHBWxegZj"),
        treasuryMint: new PublicKey("Bic8w5K9tvHDY7XfiT7fLTdiAkQWURAKkcEs8EsXpxbY"),
        candyShopProgramId: new PublicKey("csbMUULiQfGjT8ezT16EoEBaiarS6VWRevTw1JMydrS"),
        env: "mainnet-beta",
        settings: {
          currencySymbol: "TROOP", // Currency Symbol
          currencyDecimals: 6 // Currency Decimals
          ,}
      }),
    [settings]
  );

  if (!candyShop) {
    return <></>;
  }

  return (
    <DesContainer>
      <h1 style={{ color: "white", marginTop: 40, marginBottom: 40, backgroundColor: "#4e44ce", borderRadius: 14, padding: 16 }}>Sell NFTs for $TROOP</h1>
      <Sell
        wallet={wallet}
        candyShop={candyShop}
        walletConnectComponent={<WalletMultiButton />}
        enableCacheNFT={true}
      />
    </DesContainer>
  );
};

export default SellForTroop;

const DesContainer = styled.div`
  width: 100%;

  .wallet-adapter-button {
    margin: 0 auto;
  }
`;
